<template>
  <SvSelect
      clearable
      :disabled="schoolId === 0 || disabled"
      :label="label"
      :error="error"
      :options="suggests.list"
      :loading="suggests.load && loading"
      value-key="fio"
      :model-value="suggests.teacher"
      :placeholder="schoolId ? 'Выберите учителя' : 'Необходимо заполнить школу'"
      @change="handleChange"
      @focus="$emit('focus', $event)"
      @input="search">
  </SvSelect>
</template>

<script>
import API from '@/api'

import {SvSelect} from "@/components/default";

export default {
  name: "TeacherID",
  components: {
    SvSelect,
  },
  emits: [
    "change",
    "focus"
  ],
  props: {
    value: {type: Number, required: true},
    schoolId: {type: Number, required: true},
    loading: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    teacher: {type: Object, default: new Object({})},
    label: {type: String, default: ""},
    error: {type: String, default: ""}
  },
  data() {
    return {
      suggests: {
        teacher: this.teacher,
        load: false,
        list: []
      }
    }
  },
  methods: {
    handleChange(evt) {
      if (evt?.id === undefined) {
        this.suggests.teacher = {}
        this.$emit("change", 0)

        return
      }

      this.suggests.teacher = evt
      this.$emit("change", evt.id)
    },

    search(name) {
      this.suggests.load = true

      const fn = () => {
        API.teachers.list({similar: name, limit: 7, schoolID: this.schoolId}).then((resp) => {
          resp.data?.list?.forEach((val) => {
            val.fio = this.teacherFIO(val)
          })

          this.suggests.list = resp.data.list || []
          this.suggests.load = false
        })
      }

      API.debounce(fn, 250)
    },

    teacherFIO(data) {
      return `${data.lastName || ''} ${data.firstName || ''} ${data.middleName || ''}`.trim()
    },
  },
  watch: {
    schoolId() {
      this.handleChange(0)
      this.search("")
    }
  },
  mounted() {
    this.search("")
  }
}
</script>

<style lang="scss" scoped>
.select {
  &-search-option {
    color: var(--sovunyaGray40);
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
