<template>
  <SvSelect
      clearable
      ref="select"
      :label="label"
      :error="error"
      :disabled="disabled"
      :loading="suggests.load && loading"
      value-key="shortname"
      :model-value="suggests.school"
      :placeholder="placeholder"
      @change="handleChange"
      @focus="$emit('focus', $event)"
      @input="search"
      :options="suggests.list">
  </SvSelect>
</template>

<script>
import API from '@/api'

import {SvSelect} from "@/components/default";

export default {
  name: "SchoolID",
  components: {
    SvSelect,
  },
  emits: [
    "change",
    "focus"
  ],
  props: {
    value: {type: Number, required: true},
    loading: {type: Boolean, default: false},
    school: {type: Object, default: new Object({})},
    disabled: {type: Boolean, default: false},
    city: {type: String, default: ""},
    error: {type: String, default: ""},
    label: {type: String, default: ""},
    placeholder: {type: String, default: "Выберете школу"},
    withTeachers: {type: Boolean, default: false},
  },
  data() {
    return {
      suggests: {
        school: this.school,
        load: false,
        list: []
      }
    }
  },
  methods: {
    focus: function () {
      this.$refs.select.focus()
    },

    handleChange(evt) {
      if (evt?.id === undefined || evt.shortname === undefined) {
        this.suggests.school = {}
        this.$emit("change", 0)

        return
      }

      this.suggests.school = evt
      this.$emit("change", evt.id)
    },
    search(name) {
      this.suggests.load = true

      const fn = () => {
        const req = {similar: name, limit: 7, city: this.city, withTeachers: this.withTeachers}

        API.schools.list(req).then((resp) => {
          resp.data?.list?.forEach((val) => {
            val.shortname = this.shortName(val)
          })

          this.suggests.list = resp.data.list || []
          this.suggests.load = false
        })
      }

      API.debounce(fn, 250)
    },
    shortName(school) {
      let shortname = ''

      school.city ? shortname += `г. ${school.city}, ` : ''
      school.name ? shortname += `${school.name}` : ''

      return shortname
    },
  },
  watch: {
    city() {
      this.handleChange(0)
      this.search("")
    }
  },
  mounted() {
    this.search("")
  }
}
</script>

<style lang="scss" scoped>
.select {
  &-search-option {
    color: var(--sovunyaGray40);
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
