<template>
  <Sidebar
      title="Создание заказа"
      @close="close()">

    <template v-slot:content>
      <form autocomplete="off" @submit.prevent="create(form)">
        <SelectSchoolID
            class="form-item"
            label="Школа"
            with-teachers
            :error="error.schoolID"
            :value="form.schoolID"
            @change="changeSchool"
            @focus="error.schoolID = ''"/>

        <SelectTeacherID
            class="form-item"
            label="Учитель"
            :school-id="form.schoolID"
            :error="error.teacherID"
            :value="form.teacherID"
            @change="form.teacherID = $event"
            @focus="error.teacherID = ''"/>

        <SvInput
            class="form-item"
            type="textarea"
            label="Комментарий"
            placeholder="Введите комментарий..."
            :error="error.comment"
            v-model="form.comment"
            @focus="error.comment = ''"/>

        <BookList
            class="form-books"
            v-model="form.books"/>
      </form>
    </template>

    <template v-slot:buttons>
      <SvButton border primary @click="create(form)">Создать</SvButton>
    </template>

  </Sidebar>
</template>

<script>
import {mapActions} from "vuex";
import {ParseBackendErr} from "@/helpers";
import {SvButton, SvInput} from "@/components/default";

import Sidebar from "@/components/sidebar/Sidebar";
import SelectSchoolID from "@/components/select/SchoolID";
import SelectTeacherID from "@/components/select/TeacherID";
import BookList from "@/pages/orders/forms/book-list/BookList";

export default {
  name: "Create",
  components: {
    SvButton,
    SvInput,
    Sidebar,
    SelectSchoolID,
    SelectTeacherID,
    BookList,
  },
  emits: [
    "close",
  ],
  data() {
    return {
      form: {
        schoolID: 0,
        teacherID: 0,
        books: []
      },
      error: {}
    }
  },
  methods: {
    ...mapActions("orders", [
      "createOrder",
    ]),

    changeSchool(schoolID) {
      this.form.schoolID = schoolID
      this.form.teacherID = 0
      this.form.teacher = {}
    },

    create(data) {
      this.createOrder(data).then(() => {
        this.$message.success("Сохранено успешно")
        this.close()
      }).catch(err => {
        this.error = ParseBackendErr(err)
      })
    },

    close() {
      this.form = {}
      this.error = {}
      this.$emit("close")
    },
  }
}
</script>

<style lang="scss" scoped>
.form-books {
  margin-top: 40px;
}
</style>
