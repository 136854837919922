<template>
  <div class="card" @contextmenu.prevent="$emit('rightClick', order.id)">
    <div class="card-header">
      <Badge selectable :state="order.state" @change="updateState"/>
      <div class="card-header__date">{{ createdAt }}</div>
    </div>

    <div class="card-content">
      <div class="card-content__name"
           @click="$emit('rightClick', order.id)">Заказ № {{ order.id }}
      </div>

      <div class="card-content__info">
        <div class="card-content__info-item" v-for="(value, idx) in info" :key="`info_${idx}`">
          <div class="label">{{ value.label }}:</div>
          <div class="text">{{ value.text }}</div>
        </div>
      </div>

      <div class="card-content__books">
        <div class="card-content__books-item" v-for="(value, idx) in books" :key="`book_${idx}`">
          <div class="label">{{ value.name }}</div>
          <div class="text">{{ value.count }} шт</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {ORDER_STATES} from "@/constants/order-states"

import Badge from "@/pages/orders/badge/Badge";

export default {
  name: "Card",
  components: {
    Badge
  },
  props: {
    order: {type: Object, required: true}
  },
  emits: [
    "rightClick"
  ],
  methods: {
    ...mapActions("orders", [
      "updateOrderState",
    ]),

    updateState(state) {
      this.updateOrderState({...this.order, state: state})
    },
  },
  computed: {
    info() {
      let info = [];

      if (this.order.school.name) info.push({
        label: "Школа",
        text: this.order.school.name,
      })

      if (this.order.school.addr) info.push({
        label: "Адрес",
        text: "г. " + this.order.school.city + ", " + this.order.school.addr,
      })

      if (this.order.teacher.phone) info.push({
        label: "Телефон",
        text: this.order.teacher.phone,
      })

      if (this.order.teacher.fio) info.push({
        label: "Учитель",
        text: this.order.teacher.fio,
      })

      if (this.order.comment) info.push({
        label: "Комментарий",
        text: this.order.comment,
      })

      return info
    },
    books() {
      return this.order.books
    },
    createdAt() {
      return new Date(this.order.createdAt * 1000).toLocaleString()
    },
    state() {
      return ORDER_STATES[this.order.state]
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: var(--sv-theme-layout);
  border: 1px solid rgba(228, 228, 228, 0.6);
  box-sizing: border-box;
  box-shadow: 0 8px 8px 0 rgb(0 0 0 / 10%);
  border-radius: 10px;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 15px;
    width: 100%;
    border-bottom: 0.5px solid rgba(228, 228, 228, 0.6);

    &__date {
      font-style: normal;
      font-weight: 300;
      font-size: .9rem;
      line-height: .9rem;
      display: flex;
      align-items: center;
      text-align: right;
      color: #999999;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    &__name {
      width: 100%;
      font-style: normal;
      font-weight: 900;
      font-size: 1.3rem;
      padding: 10px 16px;
      border-bottom: 0.5px solid rgba(228, 228, 228, 0.6);
      opacity: .85;
      cursor: pointer;
    }

    &__info {
      width: 100%;
      padding: 10px 16px;
      border-bottom: 0.5px solid rgba(228, 228, 228, 0.6);

      &-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;

        .label {
          font-style: normal;
          font-weight: normal;
          font-size: .9rem;
          color: rgba(var(--sv-text), .8);
        }

        .text {
          font-style: normal;
          font-weight: 700;
          font-size: .9rem;
          line-height: 1.5rem;
          color: #2C3E50;
          text-align: end;
          max-width: 70%;
        }
      }
    }

    &__books {
      width: 100%;
      padding: 10px 16px;
      border-bottom: 0.5px solid rgba(228, 228, 228, 0.6);
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        font-style: normal;
        font-weight: normal;
        font-size: .9rem;
        line-height: 1.5rem;
        color: rgba(var(--sv-text), 1);
        margin: 8px 0;

        .label {
          width: 80%;
        }

        .text {
          color: rgba(var(--sv-text), 1);
          text-align: end;
        }
      }
    }
  }
}
</style>
