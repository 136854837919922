<template>
  <div class="books">
    <div class="books-item" v-for="(book, idx) in modelValue" :key="`book_key_${idx}`">
      <span>{{ book.name }}</span>

      <div class="books-item-group">
        <SvInput
            class="books-item-group-input-number"
            type="number"
            :min="0"
            :ref="`book_key_${idx}`"
            v-model.number="book.count"/>

        <i class="el-icon-delete books-item-icon"
           @click="removeBook(book)"/>
      </div>
    </div>

    <div class="books-appender">
      <SelectBook
          class="books-appender-search"
          label="Добавление книги"
          placeholder="Выберете книгу..."
          @change="appendBook($event)"/>
    </div>
  </div>
</template>

<script>
import {SvInput} from "@/components/default";

import SelectBook from "@/components/select/Book";

export default {
  name: "BookList",
  components: {
    SvInput,
    SelectBook,
  },
  props: {
    modelValue: {type: Array},
  },
  methods: {
    appendBook(book) {
      if (!book?.id) {
        return
      }

      const exists = this.modelValue.some(value => {
        return book.id === value.id
      })

      if (exists) {
        this.$notify.warning({
          title: 'Warning',
          message: `Книга ${book.name} уже добавлена!`
        });

        return
      }

      const value = this.modelValue

      value.push(JSON.parse(JSON.stringify(book)))
      this.$emit("update:modelValue", value)

      this.$nextTick(() => {
        this.$refs[`book_key_${this.modelValue.length - 1}`].focus()
      });
    },

    removeBook(book) {
      const index = this.modelValue.indexOf(book);

      const value = this.modelValue

      value.splice(index, 1);
      this.$emit("update:modelValue", value)
    },
  },
}
</script>

<style lang="scss">
.books {
  &-item {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 8px 0;
    border-bottom: 1px solid #EBEEF5;
    font-size: .9rem;

    &-icon {
      margin-left: 16px;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
      transition: all 200ms ease-out;
      color: rgba(var(--sv-text), .6);
    }

    &-icon:hover {
      color: rgba(var(--sv-danger), 1) !important;
    }

    &-group {
      display: flex;
      flex-direction: row;
      align-items: center;

      .input-content-inner {
        height: 30px !important;
      }

      &-input-number {
        width: 5rem !important;
      }
    }
  }

  &-appender {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    &-icon {
      margin-left: 16px;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
      transition: all 200ms ease-out;
      color: var(--sovunyaGray40);
    }

    &-search {
      width: 100%;

      .select-input-content-inner {
        height: 40px !important;
      }
    }
  }
}
</style>
