<template>
  <div class="page">
    <div class="page-header">
      <h3 class="page-title">Заказы</h3>
      <div class="button">
        <SvButton primary @click="form.active ='create'">Создать</SvButton>
      </div>
    </div>

    <Filters
        :city="filter.city"
        :school-id="filter.schoolID"
        :states="filter.states"
        @changeCity="filterBy('city', $event)"
        @changeSchoolID="filterBy('schoolID', $event)"
        @changeStates="filterBy('states', $event)"/>

    <div class="page-data">
      <Card
          class="card"
          v-for="(order, idx) in orders"
          :key="`order_${idx}`"
          :order="order"
          @rightClick="startUpdate(order.id)"/>

      <Create
          v-if="form.active === 'create'"
          @close="close()"/>

      <Update
          v-else-if="form.active === 'update'"
          :data="form.value"
          @close="close()"/>
    </div>

    <SvScrollTrigger @triggered="fetchNextOrders"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {SvButton, SvScrollTrigger} from "@/components/default";

import Filters from '@/pages/orders/filters/Filters';
import Card from "@/pages/orders/card/Card";
import Create from "@/pages/orders/forms/Create";
import Update from "@/pages/orders/forms/Update";

export default {
  name: "Orders",
  components: {
    SvButton,
    SvScrollTrigger,
    Filters,
    Card,
    Create,
    Update,
  },
  data() {
    return {
      form: {
        active: "",
        value: {},
      },
    }
  },
  methods: {
    ...mapActions("orders", [
      "fetchOrders",
      "fetchNextOrders",
      "filterOrders",
      "findOrder",
      "resetState",
    ]),

    filterBy(key, value) {
      this.filterOrders({key: key, value: value})
    },

    async startUpdate(orderID) {
      if (this.$route.query.id !== orderID) {
        await this.$router.push(`/orders?id=${orderID}`)
      }

      this.form.value = await this.findOrder(orderID)
      this.form.active = "update"
    },

    close() {
      if (this.$route.query.id) {
        this.$router.push(`/orders`)
      }

      this.form.active = ""
    },
  },
  computed: {
    ...mapGetters("orders", [
      "orders",
      "filter",
    ]),
  },
  created() {
    this.fetchOrders().catch(err => {
      console.error(err)
    })
  },
  unmounted() {
    this.resetState()
  }
}
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  padding: 0 1.3rem;
  transition: width .3s ease;

  &-title {
    padding-left: .9rem;
    font-size: 2rem;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

  &-data {
    .card {
      margin-bottom: 15px;
    }
  }
}

.button {
  margin: auto 0;
}
</style>
