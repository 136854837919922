<template>
  <Sidebar
      title="Редактирование заказа"
      @close="close()">

    <template v-slot:content>
      <Badge selectable :state="form.state" @change="updateState"/>

      <form autocomplete="off" @submit.prevent="update(form)">
        <SelectSchoolID
            class="form-item"
            label="Школа"
            with-teachers
            :disabled="form.state !== 'CREATED'"
            :school="form.school"
            :name="form.school.shortname"
            :error="error.schoolID"
            :value="form.schoolID"
            @change="form.schoolID = $event"
            @focus="error.schoolID = ''"/>

        <SelectTeacherID
            class="form-item"
            label="Учитель"
            :disabled="form.state !== 'CREATED'"
            :error="error.teacherID"
            :teacher="form.teacher"
            :school-id="form.schoolID"
            :value="form.teacherID"
            @change="changeSchool"
            @focus="error.teacherID = ''"/>

        <SvInput
            class="form-item"
            label="Комментарий"
            :error="error.comment"
            type="textarea"
            placeholder="Введите комментарий..."
            v-model="form.comment"
            @focus="error.comment = ''"/>

        <BookList
            class="form-books"
            v-model="form.books"/>
      </form>
    </template>

    <template v-slot:buttons>
      <SvButton border primary @click="update(form)">Сохранить</SvButton>
    </template>
  </Sidebar>
</template>

<script>
import {mapActions} from "vuex";
import {ParseBackendErr} from "@/helpers";
import {ORDER_STATES} from "@/constants/order-states";
import {SvButton, SvInput} from "@/components/default";

import Sidebar from "@/components/sidebar/Sidebar";
import SelectSchoolID from "@/components/select/SchoolID";
import SelectTeacherID from "@/components/select/TeacherID";
import BookList from "@/pages/orders/forms/book-list/BookList";
import Badge from "@/pages/orders/badge/Badge";

export default {
  name: "Create",
  components: {
    SvButton,
    SvInput,
    BookList,
    Badge,
    Sidebar,
    SelectSchoolID,
    SelectTeacherID,
  },
  emits: [
    "close",
  ],
  props: {
    data: {type: Object, required: true}
  },
  data() {
    return {
      book: {},
      form: JSON.parse(JSON.stringify(this.data)),
      error: {}
    }
  },
  methods: {
    ...mapActions("orders", [
      "updateOrder",
      "updateOrderState",
    ]),

    changeSchool(schoolID) {
      this.form.schoolID = schoolID
      this.form.teacherID = 0
      this.form.teacher = {}
    },

    update(data) {
      this.updateOrder(data).then(() => {
        this.$message.success("Сохранено успешно")
        this.close()
      }).catch((err) => {
        this.error = ParseBackendErr(err)
      })
    },

    updateState(state) {
      this.form.state = state

      this.updateOrderState(this.form).then(() => {
      }).catch((err) => {
        this.error = ParseBackendErr(err)
      })
    },

    close() {
      this.form = {}
      this.error = {}
      this.$emit("close")
    },
  },
  computed: {
    state() {
      return ORDER_STATES[this.form.state]
    }
  }
}
</script>

<style lang="scss" scoped>
.form-books {
  margin-top: 40px;
}

.badge {
  margin: 10px 0 20px 0;
  font-style: normal;
  font-weight: normal;
  font-size: .9rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: rgba(var(--sv-color), 1);

  .icon {
    width: 1.1rem;
    height: 1.1rem;
    min-width: 1.1rem;
    min-height: 1.1rem;
    margin-right: .5rem;
  }
}
</style>
