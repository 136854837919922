<template>
  <SvSelect
      :label="label"
      :loading="suggests.load && loading"
      value-key="name"
      v-model="value"
      :placeholder="placeholder"
      @change="change"
      @input="search"
      :options="suggests.list">
  </SvSelect>
</template>

<script>
import API from '@/api'

import {SvSelect} from "@/components/default";

export default {
  name: "Book",
  components: {
    SvSelect,
  },
  emits: [
    "change",
  ],
  props: {
    label: {type: String, default: ""},
    loading: {type: Boolean, default: false},
    placeholder: {type: String, default: "Выберите книгу"},
  },
  data() {
    return {
      suggests: {
        load: false,
        list: []
      },
      value: {},
    }
  },
  methods: {
    change(item) {
      this.value = {}

      if (item.id === undefined || item.name === undefined) {
        this.$emit("change", {})

        return
      }

      this.$emit("change", item)
    },
    search(name) {
      const fn = () => {
        API.books.list({similar: name, limit: 7}).then((resp) => {
          this.suggests.list = resp.data.list || []
        })
      }

      API.debounce(fn, 250)
    },
  },
  mounted() {
    this.search("")
  }
}
</script>

<style lang="scss" scoped>
</style>
