<template>
  <div class="badge"
       :class="[`badge${currentState?.color}`, selectable ? 'pointer' : '']"
       @click="isActive = !isActive"
       v-click-outside="close">
    <i class="icon"
       :class="currentState?.icon"/>{{ currentState?.name }}

    <ul class="list" v-if="isActive && selectable">
      <template v-for="(state, idx) in availableStates" :key="`state_${idx}`">
        <li class="list-item badge" :class="`badge${state.color}`" @click="change(state.key)">
          <i class="icon" :class="state.icon"/>{{ state.name }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import ClickOutside from "@/directives/click-outside";
import {ORDER_STATES} from "@/constants/order-states";

export default {
  name: "Badge",
  props: {
    state: {type: String, required: true},
    selectable: {type: Boolean, default: false},
  },
  emits: [
    "change"
  ],
  directives: {
    ClickOutside: ClickOutside,
  },
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    close() {
      this.isActive = false
    },
    change(state) {
      this.$emit("change", state)
    }
  },
  computed: {
    currentState() {
      return ORDER_STATES[this.state]
    },
    availableStates() {
      let states = []

      for (let state in ORDER_STATES) {
        if (this.state !== state) {
          states.push({key: state, ...ORDER_STATES[state]})
        }
      }

      return states
    }
  }
}
</script>

<style lang="scss" scoped>
.badge {
  font-style: normal;
  font-weight: normal;
  font-size: .8rem;
  line-height: .9rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: rgba(var(--sv-color), 1);
  position: relative;

  .icon {
    width: 1rem;
    height: 1rem;
    min-width: 1rem;
    min-height: 1rem;
    margin-right: .5rem;
  }
}

.list {
  position: absolute;
  top: 15px;
  z-index: 5;
  background: var(--sv-theme-layout);
  border: 1px solid var(--sovunyaGray90);
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  padding: 5px;

  &-item {
    padding: 5px;
  }

  &-item:hover {
    background: rgba(var(--sv-primary), .05);
  }
}

.pointer {
  cursor: pointer;
}

</style>