export const ORDER_STATES = {
    CREATED: {
        name: "Новый",
        color: "--primary",
        icon: "sv-more",
    },
    SHIPPED: {
        name: "Доставлен",
        color: "--warn",
        icon: "sv-clock",
    },
    PAID: {
        name: "Оплачен",
        color: "--success",
        icon: "sv-check",
    },
    CANCELED: {
        name: "Отменен",
        color: "--danger",
        icon: "sv-x-circle",
    },
}
