<template>
  <div class="filters">
    <SelectCity
        class="filters-field"
        :value="city"
        @change="$emit('changeCity', $event || '')"
        placeholder="Выберите город"/>

    <SelectSchoolID
        class="filters-field city-select"
        :value="schoolId"
        :city="city"
        @change="$emit('changeSchoolID', $event || 0)"/>
  </div>
</template>

<script>
import SelectSchoolID from "@/components/select/SchoolID";
import SelectCity from "@/components/select/City";

export default {
  name: "Filter",
  components: {
    SelectCity,
    SelectSchoolID,
  },
  emits: [
    "changeCity",
    "changeSchoolID",
    "changeStates",
  ],
  props: {
    city: {type: String, required: true},
    schoolId: {type: Number, required: true},
    states: {type: Array, required: true},
  },
}
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  align-items: flex-end;
  padding-top: 4px;
  padding-bottom: 16px;

  &-field {
    height: 36px;
    margin-right: 12px;

    &.city-select {
      width: 350px;
    }
  }
}
</style>
